import Vue from 'vue'
import App from './App.vue'
import store from './store'
import { router } from './router'
import Auth from '@aws-amplify/auth'
import vuetify from '@/plugins/vuetify'
import { AWS_CONFIG } from '@/config/auth'
import { ACCOUNTS_BASE, HOTJAR_ID, ENABLE_TRACKING, VALIDATIONS_GA4_PROPERTY, VALIDATIONS_GA3_PROPERTY } from '@/config/constants'

import Hotjar from 'vue-hotjar'
import Gtag from 'vue-gtag'

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import { setDocumentTitle } from './helpers/document_title'

Vue.config.productionTip = false
Vue.use(Vuetify)

Vue.use(Hotjar, {
  id: HOTJAR_ID,
  isProduction: ENABLE_TRACKING
})

Vue.use(Gtag, {
  config: {
    id: VALIDATIONS_GA4_PROPERTY
  },
  includes: [{
    id: VALIDATIONS_GA3_PROPERTY
  }],
  enabled: ENABLE_TRACKING
}, router)

let isTenantAvailable = false
// Tenant config
const tenant = new Promise<void>((resolve, reject) => {
  if (isTenantAvailable) {
    resolve()
    return
  }

  store.dispatch('auth/tenant').then((tenant) => {
    AWS_CONFIG.Auth.identityPoolId = tenant.identity_pool_id
    AWS_CONFIG.Auth.userPoolId = tenant.user_pool_id
    AWS_CONFIG.Auth.userPoolWebClientId = tenant.client_id
    Auth.configure(AWS_CONFIG.Auth)
    isTenantAvailable = true

    resolve()
  }).catch((e) => {
    // eslint-disable-next-line no-console
    console.log(e)
    reject(e)
  })
})

// Check auth
router.beforeEach((to, from, next) => {
  setDocumentTitle(to)

  if (window.location.hostname === "localhost") {
    next(); // Do not redirect to accounts for auth if running locally

    return
  }

  if (to.path === '/logout') {
    store.dispatch('auth/logout').catch(e => {
      console.log(e)
    })
  }

  tenant.then(() => store.dispatch('auth/authenticate').then(() => {
    next()
  })).catch((e) => {
    // eslint-disable-next-line no-console
    console.log(e)
    if (to?.meta && !to.meta.requiresAuth) {
      next()
      return
    }

    window.location.href = ACCOUNTS_BASE
  })
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
